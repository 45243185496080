document.addEventListener('DOMContentLoaded', function() {

    var menuTrigger = document.getElementById('menu-trigger');
    var menu = document.getElementById('menu')
    menuTrigger.addEventListener('click', function () {
        console.log(123);
        menu.classList.toggle('hidden')
    })

});

console.log(123);